@import 'bootstrap/bootstrap.less';
@import (less)  '../../../bower_components/fullpage.js/jquery.fullPage.css';
@import 'imagelightbox.less';

body {
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  line-height:150%;
}

h1 {
  text-transform:uppercase;
  font-size:18px;
  margin-top:10px;
  margin-bottom:20px;
  font-weight:bold;
}

.section-start a {
  display:block;
  float:left;
  width:33.3333%;
  height:100%;
  text-align:center;
  line-height:100%;
  background-repeat:no-repeat;
  background-position:center center;
  background-size: cover;

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }


  img {
    width:250px;
    max-width:100%;
    vertical-align: middle;
    -webkit-transition: all 0.5s ease; /* Safari and Chrome */
    -moz-transition: all 0.5s ease; /* Firefox */
    -ms-transition: all 0.5s ease; /* IE 9 */
    -o-transition: all 0.5s ease; /* Opera */
    transition: all 0.5s ease;
  }

  &:hover img {
    -webkit-transform:scale(1.1); /* Safari and Chrome */
    -moz-transform:scale(1.1); /* Firefox */
    -ms-transform:scale(1.1); /* IE 9 */
    -o-transform:scale(1.1); /* Opera */
    transform:scale(1.1);
  }
}

.section-content, .maps-title {
  -webkit-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.3);
  box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.3);

  h1 img {
    -webkit-transition: all 0.3s ease; /* Safari and Chrome */
    -moz-transition: all 0.3s ease; /* Firefox */
    -ms-transition: all 0.3s ease; /* IE 9 */
    -o-transition: all 0.3s ease; /* Opera */
    transition: all 0.3s ease;

    &:hover {
      -webkit-transform:scale(1.1); /* Safari and Chrome */
      -moz-transform:scale(1.1); /* Firefox */
      -ms-transform:scale(1.1); /* IE 9 */
      -o-transform:scale(1.1); /* Opera */
      transform:scale(1.1);
    }
  }

  &:hover {
    h1 img {

    }
  }
}

#link-schietveld {
  background-image:url('../../img/mout2.jpg');
}

#link-sergeant {
  background-image:url('../../img/sergeant-bg.jpg');
}

#link-kolonel {
  background-image:url('../../img/kolonel-bg.jpg');
}

nav {
  position:fixed;
  width:100%;
  left:0;
  top:0;
  z-index:999;
  background:black;

  ul {
    margin:0;
    padding:0;
  }

  li {
    float:left;
    list-style-type: none;
    padding:10px 15px;
    margin:0;
    color:#ffffff;
  }
}

.section-schietveld, .section-sergeant, .section-kolonel, .section-verkoop {
  text-align:center;
}

#buttons {
  position:fixed;
  right:10px;
  bottom:10px;
}

#fb, #mail {
  text-decoration:none;
  font-size:15px;
  padding:10px;
  transition:0.3s;
  opacity:0.7;
  display:inline-block;

  &:hover, &:active &:focus {
    opacity:1;
  }
}

#mail {
  background:black;
  color:#ffffff;
}

#fb {
  color:#ffffff;
  background:#3B5998;
}

.section-schietveld, .section-sergeant, .section-kolonel, .section-verkoop, .slide-gallery {
  background-repeat:no-repeat;
  background-position:center center;
  background-size: cover;
}

.section-schietveld {
  background-image:url(../../img/maischen.jpg);


  .section-content {
    color: #dfdfdf;
    background: rgba(0, 0, 0, 0.8);
    position: relative;
    z-index: 2;
    a {
      color: #dfdfdf;
      text-decoration: underline;
    }
  }
}

.section-kolonel {
  background-image:url(../../img/schietveld.jpg);

  .section-content {
    color:#f6efd7;
    background:rgba(2, 52, 30, 0.8);

    a {
      color:#f6efd7;
      text-decoration:underline;
    }
  }
}

.section-sergeant {
  background-image:url(../../img/hop.jpg);

  .section-content {
    color:#623b27;
    background:rgba(254, 254, 241, 0.8);

    a {
      color:#623b27;
      text-decoration:underline;
    }
  }
}

.section-verkoop {
  background-image:url(../../img/schietveld.jpg);
}

.section-content {
  padding:30px;
  margin:0 10%;

  h1 img {
    max-width:100%;
    max-height:150px;
  }

  a {
    color:#e40426;
  }
}


.gallery {
  padding-top:30px;

  a {
    padding: 0px 5px;
    margin-bottom:10px;
    display:inline-block;

    img {
      border:2px solid rgba(2, 52, 30, 0.8);
      transition:0.3s;

      &:hover {
        border-color:#f6efd7;
      }
    }
  }
}

.maps-title {
  background:rgba(255, 255, 255, 0.8);
  padding:20px;
  z-index:9999;
  position:fixed;
  left:20px;
  top:20px;
  display:none;
}

@media(max-width: @screen-sm-max) {
  #buttons {
    width:100%;
    bottom:0;
    left:0;
    font-size:0;
  }
  #fb, #mail {
    width:50%;
    text-align:center;
    opacity:1;
  }

  .maps-title {
    display:none !important;
  }

  .section-content {
    margin: 5%;
  }
}

.fp-responsive .fp-tableCell {
  height:auto !important;
}